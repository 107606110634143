/**
 * Produces a replacement `Node` for the search text `what` found in the DOM at `where`,
 * or `false` if no replacement should take place.
 *
 * @param what An object defining the search text that was found.
 * @param where The position in the DOM where the search text was found.
 */
export type CreateReplacementNodeCallback = (what: HasTextProperty, where: TextPosition) => Node | false;

/**
 * Any object with a `text` property, which will be interpreted as a text to be searched for.
 */
export interface HasTextProperty {
    text: string;
}

/**
 * A character position inside a DOM `Text` node.
 */
export interface TextPosition {
    node: Text;
    index: number;
}

/**
 * Searches the DOM subtree beneath `root` for the search texts specified by `what`,
 * and replaces occurrences of these search texts with nodes produced by the given callback function `createReplacementNode`.
 *
 * @param createReplacementNode A function responsible for replacement nodes for search text occurrences.
 *   The function will receive both the found search text (`what`) along with a position in the DOM
 *   `where` the text was found. It can return either a replacement `Node`,
 *   or `false` if no replacement should happen for this occurrence of the search text.
 * @param root Root of the DOM subtree to be searched for the given search texts.
 * @param what An array of objects, each of which specifies a search text via a `text` property.
 */
export function replaceTextWithNodes(createReplacementNode: CreateReplacementNodeCallback, root: Node, ...what: HasTextProperty[]) {

    const document = root.ownerDocument;
    const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT, node => NodeFilter.FILTER_ACCEPT, false);
    //                                                                   ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    //                                     Should be an object implementing `NodeFilter`, which IE doesn't support.
    //                                     Using a function instead appears to work across all major browsers.

    let doNotAdvanceToNextNode = false;

    while (doNotAdvanceToNextNode || walker.nextNode()) {
        doNotAdvanceToNextNode = false;

        let node = walker.currentNode as Text;
        let nodeTextContent = node.textContent.toLowerCase();

        // find all occurrences of all search texts in the text node:
        const unsortedMatches = what.reduce((occurrences, what) => {
            const whatText = what.text.toLowerCase();
            let index = 0;
            while (index >= 0) {
                index = nodeTextContent.indexOf(whatText, index);
                if (index >= 0) {
                    occurrences.push({ what, index });
                    index += whatText.length;
                }
            }
            return occurrences;
        }, []);

        // these search results get sorted as follows:
        //  1. by their position in the searched text
        //  2. by search text length (longer search texts first)
        const matches = unsortedMatches.sort((a, b) => a.index - b.index || b.what.text.length - a.what.text.length);

        // attempt to replace a search result until one replacement succeeds
        // (the replacement function may opt to not provide a replacement, in which case
        // replacement will be tried for the next search result):
        for (const match of matches) {
            const replacementNode = createReplacementNode(match.what, { node, index: match.index });
            if (!replacementNode) continue;

            // we replace the original node with three new ones, so we use `DocumentFragment`
            // in order to achieve atomic insertion (we wouldn't want to trigger several reflows):
            const newNodes = document.createDocumentFragment();
            const prefix = document.createTextNode(node.textContent.substr(0, match.index));
            const suffix = document.createTextNode(node.textContent.substr(match.index + match.what.text.length));
            newNodes.appendChild(prefix);
            newNodes.appendChild(replacementNode);
            newNodes.appendChild(suffix);
            node.parentNode.replaceChild(newNodes, node);

            // reposition the walker, since the original node on which it is still positioned
            // is now no longer in the document:
            walker.currentNode = suffix;
            doNotAdvanceToNextNode = true;
            break;
        }
    }
}

export default replaceTextWithNodes;
