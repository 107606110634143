import View from 'ln/view/View';
import Step from './models/Step';
import Presentation from './Presentation';
import ListRenderer from 'ln/list/ListRenderer';
import Template from 'ln/template/TemplateManager';
import Node from 'ln/node/Node';
import { HoverTooltip } from 'lnui/tooltip/HoverTooltip';

class Pagination extends View {

    private presentation: Presentation;
    steps: ListRenderer<Step>;

    constructor(p: Presentation) {
        super();
        this.defaultTemplate = "";
        this.presentation = p;
    }

    init() {

        this.steps = new ListRenderer<Step>(this.node);
    
        this.steps.defaultRender((s: Step) => {
            let node = Node.fromHTML(Template.render('lernspur.pagination-step', s));
            let tooltip = new HoverTooltip( { target:node, content:s.get( 'index' ), position:'top' } ).render();
            let current = s;
            node.click.add(() => {
                this.presentation.show(current);
            });
            return {node: node};
        });
    }

    updateStep( s:Step ) {

        // check if already rendered
        if( this.steps.links.length == 0 ) this.steps.source.fill( s.lernspur.steps );

        this.steps.links.all().forEach( ( link ) => {
            link.node.toggleClass( "-active", link.item == s );
        });
    }
}

export default Pagination;