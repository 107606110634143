import View from 'ln/view/View';
import Media from '../models/Media';
import Step from '../models/Step';
import Presentation from '../Presentation';

abstract class AbstractMedia extends View {

	media:Media;
	presentation:Presentation;

	constructor( m:Media ) {
		super();
		this.media = m;
	}

	renderData() {
		return this.media;
	}

	abstract updateStep( s:Step );
	abstract renderSteps();

}

export default AbstractMedia