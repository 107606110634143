import Model from 'ln/model/Model';
import LinkedList from "ln/linkedlist/LinkedList";
import LernSpur from "ls/models/LernSpur";
import Media from "ls/models/Media";
import { mixin } from 'ln/js';

export class Step extends Model {

    // Mixin with LinkedList
    public next: LinkedList = null;
    public previous: LinkedList = null;
    public getFirst: () => LinkedList;
    public getLast: () => LinkedList;
    public addNext: () => void;
    public addPrevious: () => void;
    public toArray: () => LinkedList[];


    get media(): Media {
        return this.get<Media>('media');
    }

    get lernspur(): LernSpur {
        return this.get('media').get<LernSpur>('lernspur');
    }
}

mixin( Step, [ LinkedList ] );

export default Step;