import View from "ln/view/View";


class NavigationControl extends View {

	init() {
		this.node.js( 'navigation-menu' ).js( 'toggle' ).click.add( this.toggle.bind( this ) );
	}

	toggle() {
		this.node.js( 'navigation-menu' ).toggleClass( '-open' );
	}

	show() {
		this.node.js( 'navigation-menu' ).addClass( '-open' );
	}
	
	hide() {
		this.node.js( 'navigation-menu' ).removeClass( '-open' );
	}
}

export default NavigationControl;