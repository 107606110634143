import ElementModel from '../models/ElementModel';
import SearchResult from './SearchResult';

/**
 * SearchPattern Class.
 */
class SearchPattern {

	static IGNORE_CASE = true;
	
	public search( term:string, element:ElementModel ):SearchResult {
		var result:SearchResult = new SearchResult( element );
		return null;
	}

	protected searchString( term:string, text:string, result:SearchResult ) {

		let searchTerm = ( SearchPattern.IGNORE_CASE ) ? term.toLowerCase() : term;
		let searchText = ( SearchPattern.IGNORE_CASE ) ? text.toLowerCase() : text;

		let regex = new RegExp( searchTerm, 'g' );
		let match:RegExpMatchArray;

		while ( ( match = regex.exec( searchText ) ) !== null ) {
			result.makeExcerpt( text, match.index, searchTerm.length );
		}
	}
}

export default SearchPattern;