import View from 'ln/view/View';
import Node from 'ln/node/Node';
import Request from 'ln/request/Request';
import setup from 'ln/setup/setup';
import BookModel from 'lb/models/BookModel';

interface Book {
	feedbackEmail:string,
	title:string,
}

class Feedback extends View {

	public book:Book;

	constructor( book:Book ) {
		super();

		this.book = book;
	}

	init() {

		// only show feedback if its defined
		if( this.book.feedbackEmail.length > 0 ) {
			Node.js( 'feedback-link').click.add( ( node, event ) => {
				event.preventDefault();
				this.showPanel();
			});
		}

		this.node.js( 'submit-form' ).click.add( this.submit.bind( this ) );
		this.node.js( 'close' ).click.add( this.hidePanel.bind( this ) );

		this.node.click.add( ( listener, e ) => {
			if( e.target == this.node.native ) this.hidePanel();
		});
	}


	showPanel() {
		this.node.removeClass( 'hidden' );
		this.node.js( 'error-message' ).addClass( 'hidden' );
		this.node.js( 'success-message' ).addClass( 'hidden' );
		this.node.js( 'submit-form' ).removeClass( '-disabled' );
	}

	hidePanel() {
		this.node.addClass( 'hidden' );
	}

	submit() {

		if( this.node.js( 'submit-form' ).hasClass( '-disabled' )) return; 

		this.node.js( 'error-message' ).addClass( 'hidden' );
		this.node.js( 'success-message' ).addClass( 'hidden' );

		var data = {
			recipient: this.book.feedbackEmail,
			book: this.book.title,
		};

		var isValid = true;

		this.node.js( 'feedback-form' ).all( 'input, textarea' ).forEach( function( node ) {
			var value = node.value;
			node.parent().parent().toggleClass( '-invalid', value == '' );
			data[ node.getAttribute( 'name' ) ] = value;
			isValid = isValid && value != '';
		});

		if( !isValid ) return;

		this.node.js( 'submit-form' ).addClass( '-disabled' );

		Request.post( setup.route( 'feedbackapi-endpoint' ).url() )
			.headers( { 'Content-Type':'application/json',
			'Accept':'text/html,application/json' } )
			.send( JSON.stringify(data) )
			.then( this.onSubmit.bind( this ) )
			.catch( this.onError.bind( this ) );
	}

	onError() {
		this.node.js( 'error-message' ).removeClass( 'hidden' );
	}

	onSubmit() {
		// clear form
		this.node.js( 'feedback-form' ).all( 'input, textarea' ).forEach( function( node ) {
			node.value = '';
		});

		this.node.js( 'submit-form' ).removeClass( '-disabled' );
		this.node.js( 'success-message' ).removeClass( 'hidden' );
	}

}

export default Feedback;