import View from 'ln/view/View';
import ListRenderer from 'ln/list/ListRenderer';
import Step from './models/Step';
import Presentation from './Presentation';
import { Signal, EmptyCallback } from "ln/signal/Signal";


class Sidebar extends View {

    toggle: Signal<EmptyCallback> = new Signal<EmptyCallback>();
    content:ListRenderer<Object>;

    constructor() {
        super();
        this.defaultTemplate = "lernspur.sidebar";
        this.content = new ListRenderer<Object>();
    }

    init() {
        this.content.container = this.node.js( 'content' );
    }

    register(p: Presentation) {
        p.change.add(this.onStep.bind(this));
    }

    /**
     * Callback if a new step is shown
     */
    onStep(s: Step) {

        this.content.source.fill( s.get( 'content' ) );

        this.node.js("stepIndex").html = s.get('index');
        this.node.js("stepCount").html = String(s.lernspur.steps.length);
    }

    onOpen() {
        this.node.removeClass("-hide");
        this.toggle.dispatch( true );
    }

    onClose() {
        this.node.addClass("-hide");
        this.toggle.dispatch( false );
    }
}

export default Sidebar;