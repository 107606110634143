import SearchPattern from './SearchPattern';
import SearchResult from './SearchResult';
import ElementModel from '../models/ElementModel';

/**
 * ArraySearch Class.
 */
class ArraySearch extends SearchPattern {

	public attributes:Array<string>;

	/**
	 * Instantiates an ArraySearch
	 */
	constructor( attributes ) {
		super();
		this.attributes = attributes;
	}
	
	
	public search( term:string, element:ElementModel ):SearchResult {
		var result = new SearchResult( element );
		// iterate over all the attributes
		this.attributes.forEach( ( attribute ) => {
			// if there is an attribute, search on the attribute
			if (element.get(attribute)) {
				var attributeArray = element.get(attribute);
				// iterate over all the elements of the array
				for (var key in attributeArray) {
					this.searchString( term, attributeArray[key].toString(), result );
				}
			}
		});
		
		// return the result only when there is any excerpt
		return ( result.excerpts.length > 0 ) ? result : null;
	}
}

export default ArraySearch;