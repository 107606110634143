import Signal from 'ln/signal/Signal';
import Node from 'ln/node/Node';
import View from 'ln/view/View';
import template from 'ln/template/TemplateManager';
import SearchResult from './SearchResult';
import Search from './Search';
import BookModel from '../models/BookModel';


/**
 * Interface for all listener function that listen on the top signal
 */
interface SearchResultListener {
    ( term:string, results:Array<SearchResult>, ...args:any[] );
}

/**
 * SearchInput Class.
 */
class SearchInput extends View {
	
	public search:Search;
	public minChars = 3;
	public results:Signal<SearchResultListener> = new Signal<SearchResultListener>();
	
	constructor( book:BookModel, template:string = 'lb.search-input'  ) {
		
		super();
		this.defaultTemplate = template;
		this.search = new Search( book );
	}
	
	public init() {

		var input = this.node.js( 'input' );
		input.keyup.add( ()=> {
			if( input.value.length >= this.minChars ) {
				var searchResults = this.search.forString( input.value );
				this.results.dispatch( input.value, searchResults );
			}
		});
		
		input.click.add( ()=> {
			if ( input.value == "Suchen" ) {
				input.value =  "";
			}
		});
		
		input.blur.add( ()=> {
			if ( input.value == "" ) {
				input.value = "Suchen";
			}
		});
	}
}

export default SearchInput;