import { ToolTip, TooltipConfig } from './Tooltip';
import Node from '../../core/node/Node';
import { scanner } from '../../core/view/Scanner';
import TemplateManager from '../../core/template/TemplateManager';


export interface HoverTooltipConfig extends TooltipConfig {
	target:Node;
	content:string;
}

export class HoverTooltip extends ToolTip {

	config:HoverTooltipConfig;

	constructor( config:HoverTooltipConfig ) {
		super( config );
	}
	
	init() {
		this.config.target.mouseenter.add( this.onMouseOver, this );
		this.config.target.mouseleave.add( this.onMouseOut, this );

		this.content(  Node.fromHTML( TemplateManager.render( 'ui.tooltip-hover-content', this.config ) ) );
	}

	onMouseOver() {
		this.show( this.config.target.bounds() );
	}

	onMouseOut() {
		this.hide();
	}
}

export default HoverTooltip;


// register tooltip in scanner
scanner.ioc.add( 'tooltip', function( node ) {
	return new HoverTooltip( { position:node.data.position, content:node.data.content, target:node } ).render();
})