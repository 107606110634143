import ListRenderer from 'ln/list/ListRenderer';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import AbstractMedia from './AbstractMedia';
import Step from '../models/Step';
import Media from '../models/Media';

class ImageMedia extends AbstractMedia {

	steps:ListRenderer<Step>;

	constructor( m:Media ) {
		super( m );
		this.defaultTemplate = "lernspur.imagemedia";
	}

	init() {
		this.renderSteps();
    }
	
	updateStep( s:Step ) {
		this.steps.links.all().forEach( ( link ) => {
			link.node.toggleClass( "-active", link.item == s );
		});
	}

	
	renderSteps() {
		this.steps = new ListRenderer<Step>( this.node.js( "steps" ) );

		this.steps.defaultRender( ( s:Step ) => {
			let node = Node.fromHTML( Template.render( 'lernspur.step', s ) );
			let current = s;
			node.click.add( ( node:Node, event:Event ) => {
				event.stopPropagation();
				this.presentation.show( current );
			});
			return { node:node };
		});

		this.steps.source.fill( this.media.steps );
	}
}

export default ImageMedia;