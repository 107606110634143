import View from '../../core/view/View';
import Node from '../../core/node/Node';
import Template from '../../core/template/TemplateManager';


type Positions = "top" | "left" | "right" | "bottom";

export interface TooltipConfig {
	position?:Positions;
} 

/**
 * A generic tooltip that adds a node to the body on show and removes it on hide.
 */
export class ToolTip extends View {

	config:TooltipConfig;

	constructor( config:TooltipConfig = {} ) {
		super();

		if( ! config.position ) config.position = "top";

		this.config = config;
		this.defaultTemplate = 'ui.tooltip';
	}
	
	/**
	 * Sets the content of the tooltip
	 */
	content( content:Node|View ) {
		this.node.js( "content" ).append( ( content instanceof View ) ? content.node : content );
	}


	/**
	 * Shows the tooltip around the given absolute bounds
	 */
	show( bounds:{ top: number; left: number; right: number; bottom: number; height: number; width: number  } ) {

		switch (this.config.position ) {

			case "top":
				this.node.style.top = bounds.top +'px';
				this.node.style.left = (bounds.left + bounds.width/2) + 'px';
				break;
			case "bottom":
				this.node.style.top = bounds.bottom +'px';
				this.node.style.left = bounds.left + bounds.width/2 + 'px';
				break;
			case "left":
				this.node.style.top = (bounds.top + bounds.height/2) +'px';
				this.node.style.left = bounds.left + 'px';
				break;
			case "right":
				this.node.style.top = (bounds.top + bounds.height/2) +'px';
				this.node.style.left = bounds.right + 'px';
				break;
		}

		this.node.addClass( '-' + this.config.position );

		Node.fromNative( document.body ).append( this.node );
	}

	/**
	 * Hides the tooltip
	 */
	hide() {
		this.node.remove();
	}
}

export default ToolTip;