import View from 'ln/view/View';
import Signal from 'ln/signal/Signal';
import Step from './models/Step';
import Media from './models/Media';
import Sidebar from './Sidebar';
import AbstractMedia from './media/AbstractMedia';
import ImageMedia from './media/ImageMedia';
import NextPrevNavigation from './NextPrevNavigation';
import Pagination from "ls/Pagination";

interface ChangeCallback {
    (step: Step)
}


class Presentation extends View {
    
    change: Signal<ChangeCallback> = new Signal<ChangeCallback>();
    next: NextPrevNavigation;

    sidebar: Sidebar;
    currentMedia: AbstractMedia;
    pagination: Pagination;

    constructor() {
        super();
        this.defaultTemplate = "lernspur.presentation";
    }

    init() {

        // create sidebar
        this.sidebar = new Sidebar().render(this.node.js("sidebar")) as Sidebar;
        this.sidebar.register(this);
        this.sidebar.toggle.add(this.onToggleSidebar.bind(this));

        // create pagination
        this.pagination = new Pagination(this).render(this.node.js("pagination")) as Pagination;
        this.pagination

        // next & prev navi
        this.next = new NextPrevNavigation(this).render( this.node ) as NextPrevNavigation;

        this.node.js("media").click.add( this.onToggleSidebar.bind( this ) );
    }

    onToggleSidebar() {
        this.toggleSidebar( !this.node.js("content").hasClass("-margin-right") );
    }
    
    toggleSidebar( state:boolean ) {
        this.node.js("content").toggleClass("-margin-right", state );
        this.sidebar.node.toggleClass( '-hide', !state );
    }

    show(step: Step) {

        // check if we have another media
        if (this.currentMedia == undefined || (this.currentMedia && this.currentMedia.media != step.media)) {
            this.renderMedia(step.media);
        }

        // show current step
        this.currentMedia.updateStep(step);
        this.pagination.updateStep(step);


        // inform others of step change
        this.change.dispatch(step);

        this.toggleSidebar( true );
    }

    renderMedia(media: Media) {
        this.node.js("media").empty();
        this.currentMedia = new ImageMedia(media).render() as ImageMedia;
        this.node.js("media").append( this.currentMedia.node );
        this.currentMedia.presentation = this;
    }

}

export default Presentation;
