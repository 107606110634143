import View from "ln/view/View";
import Node from "ln/node/Node";
import NavigationControl from "./NavigationControl";
import { scanner } from "ln/view/Scanner";


class SearchControl extends View {

	documentHandler: (event: any) => void;

	constructor( public naviControl:NavigationControl ) {
		super();

		this.documentHandler = ( event ) => {
			this.reset();
		}
	}

	init() {
		this.node.js( 'start-search' ).click.add( this.toggle.bind( this ) );
		this.node.one( '.search-box' ).click.add( ( node:Node, event:MouseEvent ) => {
			event.stopPropagation();
		});
	}

	toggle( node:Node, event:Event ) {
		event.stopImmediatePropagation();
		var searchInput = scanner.first( 'search-input' );
		if( searchInput.node.js('input').hasClass( "hidden" ) )	 {
			this.focus();
		} else {
			this.reset();
		}
	}
	
	
	focus() {

		var searchInput = scanner.first( 'search-input' );
		searchInput.node.js('input').removeClass( "hidden" );

		// clear search
		var inputField = searchInput.node.js( 'input' );
		inputField.native.focus();
		inputField.value = '';
		
	
		this.naviControl.hide();

		document.addEventListener( 'click', this.documentHandler.bind( this ) );
	}
	
	reset() {
		var searchInput = scanner.first( 'search-input' );
		searchInput.node.js('input').addClass( "hidden" );
		
		var searchOutput = scanner.first( 'search-output' );
		searchOutput.node.removeClass( "-open" );
		
		document.removeEventListener( 'click', this.documentHandler.bind( this ) );
	}
}

export default SearchControl;