/**
 * Represents a mathematical interval `[start..end)`.
 */
export default class Interval {

    /**
     * Initializes a new instance of the `Interval` class.
     *
     * @param start The inclusive lower bound of the interval.
     * @param end The exclusive upper bound of the interval. Must be equal to or greater than `start`.
     */
    public constructor(public readonly start: number, public readonly end: number) {
        if (this.end < this.start) {
            throw new RangeError('`end` must be equal to or greater than `start`.');
        }
    }

    /**
     * Checks whether this interval is empty (`Ø`).
     */
    public get isEmpty(): boolean {
        return this.length < 0.001;
    }

    /**
     * Returns the length of this interval.
     */
    public get length(): number {
        return this.end - this.start;
    }

    /**
     * Derives a new interval from this interval by adding the specified delta to its bounds.
     *
     * @param delta The delta to add to this interval's lower and upper bounds.
     */
    public shift(delta: number): Interval;

    /**
     * Derives a new interval from this interval by adding the specified deltas to its bounds.
     *
     * @param startDelta The delta to add to this interval's lower bound.
     * @param endDelta The delta to add to this interval's upper bound.
     */
    public shift(startDelta: number, endDelta: number): Interval;

    public shift(startDelta: number, endDelta?: number): Interval {
        const endDeltaEffective = typeof endDelta === 'number' ? endDelta : startDelta;
        return new Interval(this.start + startDelta, this.end + endDeltaEffective);
    }

    /**
     * Returns the intersection of this interval and the specified `other` interval.
     * If the two intervals do not intersect, an empty interval (`Ø`) is returned.
     *
     * @param other Another interval with which to intersect this interval.
     */
    public intersectWith(other: Interval): Interval {
        if (other.end <= this.start || this.end <= other.start) {
            return new Interval(0, 0);
        }
        else {
            return new Interval(Math.max(this.start, other.start), Math.min(this.end, other.end));
        }
    }
}
