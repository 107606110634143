// import lib
import Node from 'ln/node/Node';
import LinkedList from 'ln/linkedlist/LinkedList';
import template from 'ln/template/TemplateManager';
import Hammer from 'node_modules/hammerjs/hammer.js'

// import project
import Element from 'lb/elements/Element';
import Request from 'ln/request/Request';
import setup from 'ln/setup/setup';

/**
 * An element that renders a gallery
 */
export default class IframelyElement extends Element {

    url: string;
    constructor( model ) {
        super( model );
    }

    init(){
        super.init();

        Request.get( setup.route( 'iframely', {url: encodeURIComponent(this.data.url) } ).url() )
            .load()
            .then( function(data) {
                if (data.html) {
                    this.node.native.innerHTML = data.html
                }
                else {
                    this.node.native.innerHTML = `<iframe style="width:100%; height:500px;" frameborder="0" src="${this.data.url}"></iframe>`;
                }
                
            }.bind(this))

    }

}