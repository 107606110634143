import Mapper from 'ln/model/Mapper';
import LernSpur from './LernSpur';
import Media from './Media';
import Step from './Step';
import LinkedList from 'ln/linkedlist/LinkedList';

export var mapper = new Mapper();


mapper.toModel.add('Lernspur', function (obj) {


    var ls = new LernSpur(obj),
        steps = ls.steps;

    LinkedList.fromArray(ls.mediaList);
    var first = LinkedList.fromArray(steps);
    if( first ) first.addPrevious( first.getLast() );

    ls.mediaList.forEach(m => {
        m.set('lernspur', ls);
    });

    return ls;

});

mapper.toModel.add('ImageMedia', function (obj) {
    var media = new Media(obj);

    media.steps.forEach(s => {
        s.set('media', media);
    });

    return media;
});

mapper.toModel.add('Step', function (obj) {
    return new Step(obj);
});

export default mapper;