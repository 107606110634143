import ListRenderer from 'ln/list/ListRenderer';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import 'ln/lang/Lang';
import { LernBuch } from 'lb/LernBuch';
import { mapper } from 'lb/LernBuch';
import { ioc as chapterIoC } from 'lb/elements/Chapter';


import { defaultsetup } from 'lb/setup/defaultsetup';
import setup from 'ln/setup/setup';
import { SetupConfig } from 'ln/setup/setup';

import Feedback from './Feedback';
import NavigationControl from './NavigationControl';
import SearchControl from './SearchControl';

import './templates/elements';
import './templates/lernbuch';
import './templates/lernspur';
import './templates/ui';

import Chapter from 'lb/elements/Chapter';
import Lang from 'ln/lang/Lang';

import GlossaryModel from './glossary/GlossaryModel';
import TermModel from './glossary/TermModel';
import { replaceTextWithNodes, TextPosition } from './glossary/replaceTextWithNodes';
import registerGlossaryModels from './glossary/registerModels';
import Tooltip from './glossary/Tooltip';
import View from 'ln/view/View';
import LernSpurElement from './elements/LernSpurElement';
import GalleryElement from './elements/MyCustomGallery';
import IframelyElement from './elements/Iframely';
import { mapper as lsmapper } from 'ls/models/mapper';


export function init(config: SetupConfig) {

	prepare(config);

	chapterIoC.add( 'App\\Iframely', function( element ) {
		return new IframelyElement( element ).render();
	})

	var lb = new LernBuch(setup.data('book'));
	lb.scrollMonitor.offsetTop = 56;  // = height of fixed header

	lb.render(Node.body);

	lb.navigation.renderChapters(lb.book.chapters, { modelNames: ['App\\Title'], level: 1 });

	// create feedback form
	new Feedback(lb.book).render(Node.js('feedback-container'));

	// create navi control
	var naviControl = new NavigationControl().render(lb.navigation.node) as NavigationControl;

	// controls the search input / output 
	var searchControl = new SearchControl(naviControl).render(lb.node) as SearchControl;

	const glossary: GlossaryModel = 'glossary' in config.data ? mapper.model(setup.data('glossary')) : null;

	// listen to book events.
	lb.chapterChanged.add(function (chapter) {
		searchControl.reset();
		naviControl.hide();
		Node.js('date').html = Lang.translate('last_changes') + chapter.model.formattedLastChangeDate;

		// force all a tags to open in new tab
		chapter.node.all('article a').forEach(node => {
			node.setAttribute('target', '_blank');
		});

		if (glossary) {
			for (const nodeToBeLinkedWithGlossary of chapter.node.all('[data-link-with-glossary]')) {
				replaceTextWithNodes(createGlossaryTermNode, nodeToBeLinkedWithGlossary.native, ...glossary.terms);
			}
		}

		function createGlossaryTermNode(what: TermModel, where: TextPosition) {

			// exclude short terms that aren't surrounded by delimiters:
			const index = where.index;
			if (index > 0 && !isDelimiter(where.node.textContent[index - 1])) return false;

			const lastIndex = index + what.text.length - 1;
			if (lastIndex < where.node.textContent.length - 1 && !isDelimiter(where.node.textContent[lastIndex + 1])) return false;

			// create the target node containing the term text:
			const target: HTMLElement = where.node.ownerDocument.createElement('abbr');
			target.classList.add('glossary-term');
			target.innerText = where.node.textContent.substr(where.index, what.text.length);

			// register click on term
			Node.fromNative(target).click.add( () => {
				window.open( setup.route( 'glossaryterm', what ).url(), '_blank')
			});

			// attach a tooltip view to the target node:
			const tooltip = new Tooltip({ createContent: () => createGlossaryTermTooltipContent(what) });
			tooltip.attachTo(Node.fromNative(target));
			return target;
		}

		function createGlossaryTermTooltipContent(what: TermModel): Node {
			const listRenderer = new ListRenderer(Node.fromTag('div'));
			listRenderer.ioc.add('App\\Title', item => {
				const view = new View(item);
				view.defaultTemplate = 'lb.glossary-term-tooltip-title-element';
				return view.render();
			});
			listRenderer.ioc.add('App\\Paragraph', item => {
				const view = new View(item);
				view.defaultTemplate = 'lb.glossary-term-tooltip-paragraph-element';
				return view.render();
			});
			listRenderer.defaultRender(item => new View().render());
			listRenderer.source.fill(what.elements);
			return listRenderer.container;
		}

		function isDelimiter(character) {
			return " ,.;:!?-/&%\'\"(){}[]<>\t\r\n".indexOf(character) >= 0;
		}
	});
	lb.elementChanged.add(function (chapter) {
		searchControl.reset();
		naviControl.hide();
	});
	window.addEventListener('scroll', function () {
		naviControl.hide();
	});

	// show based on the chapter slug und element hash
	lb.showSlug(setup.data('chapter'));


};


export function print(config: SetupConfig) {

	prepare(config);

	var lb = new LernBuch(setup.data('book'));

	// register custom gallery render function
	chapterIoC.add('App\\Gallery', function (config) {
		return { node: Node.fromHTML(Template.render('lb.gallery-element-list', config)) };
	});

	lb.book.chapters.forEach(chapter => {
		var chapterView = new Chapter(chapter, this).render() as Chapter;
		Node.js('chapter').append(chapterView.node);
	});

	Node.one('h1.title').addClass('-first');

}



function prepare(config) {

	defaultsetup();

	Template.context.setup = setup;

	Template.context.imagePath = function (image: { file_name, id, ext }, preset: string = 'medium', presetExt: string = 'png') {
		if (!image) return 'https://via.placeholder.com/233x150/F0F0F0/000000?text=' + Lang.translate('Bild fehlt');
		if (image.ext == 'gif') {
			preset = 'original';
			presetExt = 'gif';
		} 
		return setup.route('asset', { file_name: image.file_name, ext: image.ext, preset: preset, presetExt: presetExt }).url();
	}

	Template.context.svgPath = function (file: string = '') {
		return decodeURIComponent(setup.route('staticimage', { file_name: file }).url());
	}

	Template.context.cropImagePath = function (image: { file_name: string, width: number, height: number, ext: string }, pos: { top: number, left: number }) {
		var params: any = { width: 450, height: 300 };
		params.left = Math.round(Math.max(0, pos.left * image.width - params.width / 2));
		params.top = Math.round(Math.max(0, pos.top * image.height - params.height / 2));
		params.right = Math.round(Math.min(image.width, params.left + params.width));
		params.bottom = Math.round(Math.min(image.height, params.top + params.height));
		params.file_name = image.file_name;
		params.ext = image.ext;
		return setup.route('crop', params).url();
	}

	Template.context.icon = function (name: string, classes?: string) {
		return Template.render('lb.icon', { name: name, classes: (classes) ? classes : 'svg-icon' });
	}



	Template.context.parseDownloads = function (str: string = '') {

		var regex = /app:\/\/download\/([0-9a-z]*)/g;
		var match;
		while (match = regex.exec(str)) {
			var id = match[1];
			str = str.replace(match[0], setup.route('download', { id: id }).url());
		}

		return str;
	}

	Template.context.externalLink = function (str: string = '') {
		return str.replace(new RegExp('\<a href\=\"(?!http(s?)\:\/\/)', 'g'), '<a href="http://');
	}

	Template.context.feedbackApi = function () {
		return setup.route('feedbackapi-endpoint').url();
	}

	// do not map assets
	mapper.toModel.add('App\\Asset', function (obj) {
		return obj;
	})
	mapper.toModel.add('App\\LernSpurStep', lsmapper.toModel.get('Step'));
	mapper.toModel.add('App\\LernSpurImage', lsmapper.toModel.get('ImageMedia'));
	mapper.toModel.add('App\\LernSpur', lsmapper.toModel.get('Lernspur'));

	registerGlossaryModels(mapper);

	chapterIoC.add('App\\LernSpur', function (obj) {
		return new LernSpurElement(obj).render();
	});

	chapterIoC.add('App\\Gallery', function(obj) {
		return new GalleryElement(obj).render();
	});


	setup.init(config);

	Lang.add(setup.data('lang'));
}


