// import lib
import Node from 'ln/node/Node';
import View from 'ln/view/View';
import { ListRenderer, ILink } from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import IoC from 'ln/ioc/IoC';
import { IoCFactoryFunction } from 'ln/list/ListRenderer';

// import project
import Element from './Element';
import LernBuch from '../LernBuch';
import ChapterModel from '../models/ChapterModel';
import ElementModel from '../models/ElementModel';


export interface ChapterElementFactoryFunction extends IoCFactoryFunction<ElementModel> {
	( item:ElementModel ):Element;
}

export var ioc = new IoC<ChapterElementFactoryFunction>();

/**
 * This class renders a chapter element
 */
class Chapter extends View {
	
	public elements:ListRenderer<ElementModel>;
	
	constructor( public model:ChapterModel, public lernbuch:LernBuch) {
		
		super();
		this.elements = new ListRenderer<ElementModel>();
		this.elements.ioc = ioc;
		
		this.defaultTemplate = this.model.get( 'template', 'lb.chapter-element' );
	}

	/**
	 * Renders the chapter into its container.
	 */
	public init() {
		
		// render elements
		this.elements.container = this.node.one( '.elements' );
		this.elements.source = this.model.elements;

		this.elements.links.all().forEach( link => {
			( link as Element ).chapter = this;
		});
		
	}

	/**
	 * Define the data to render on the element
	 */
	protected renderData(){
		return this.model;
	}
	
}

export default Chapter;