import Model from 'ln/model/Model';
import Mapper from 'ln/model/Mapper';


export default class TermModel extends Model {

    public constructor(private readonly dto) {
        super(dto);
    }

    public get elements(): any[] {
        return this.get<any[]>('elements', []);
    }

    public get slug(): string {
        return this.get<string>('slug');
    }

    public get text(): string {
        return this.get<string>('title');
    }
}


export function register(mapper: Mapper) {
    mapper.toModel.add('App\\Term', dto => new TermModel(dto));
}
