import Node from 'ln/node/Node';
import View from 'ln/view/View';

export interface TooltipOptions {
    createContent: () => Node;
}

export default class Tooltip extends View {

    private hoverOverTarget: boolean;
    private hoverOverTooltip: boolean;
    private target: Node;

    public constructor(public readonly options: TooltipOptions) {
        super();
        this.defaultTemplate = 'lb.tooltip';
    }

    public attachTo(target: Node) {
        if (this.target) return;
        this.target = target;
        this.render();
    }

    protected init() {
        this.node.mouseenter.add(this.onMouseEnterTooltip, this);
        this.node.mouseleave.add(this.onMouseLeaveTooltip, this);
        this.target.mouseenter.add(this.onMouseEnterTarget, this);
        this.target.mouseleave.add(this.onMouseLeaveTarget, this);
    }

    private onMouseEnterTarget() {
        this.hoverOverTarget = true;
        this.updateVisibility();
    };

    private onMouseEnterTooltip() {
        this.hoverOverTooltip = true;
        this.updateVisibility();
    };

    private onMouseLeaveTarget() {
        this.hoverOverTarget = false;
        this.updateVisibility();
    };

    private onMouseLeaveTooltip() {
        this.hoverOverTooltip = false;
        this.updateVisibility();
    };

    private updateVisibility() {
        if (this.hoverOverTarget || this.hoverOverTooltip) {
            // If the tooltip content hasn't been rendered before, do so now:
            var contentPlaceholder = this.node.js('contentPlaceholder');
            if (contentPlaceholder) {
                contentPlaceholder.replace(this.options.createContent());
            }

            // Place the tooltip above the target node:
            var bounds = this.target.native.getBoundingClientRect();
            this.node.style.position = 'fixed';
            this.node.style.top = bounds.top + "px";
            this.node.style.left = bounds.left + bounds.width / 2 + "px";
            this.target.native.ownerDocument.body.appendChild(this.node.native);

            // If the tooltip is placed too far left, shift it back into the viewport:
            var positioned = this.node.native.getBoundingClientRect();
            if (positioned.left - positioned.width / 2 < 0) {
                this.node.style.left = positioned.width / 2 + "px";
            }
        }
        else {
            this.node.remove();
        }
    };
}
