import Model from 'ln/model/Model';
import LinkedList from "ln/linkedlist/LinkedList";
import Step from './Step';
import { mixin } from 'ln/js';

export class Media extends Model {

	// Mixin with LinkedList
	public next: LinkedList = null;
	public previous: LinkedList = null;
	public getFirst: () => LinkedList;
	public getLast: () => LinkedList;
	public addNext: () => void;
	public addPrevious: () => void;
	public toArray: () => LinkedList[];
	
	get steps():Step[] {
		return this.get<Step[]>( 'steps', [] );
	}
}

mixin( Media, [ LinkedList ] );

export default Media;