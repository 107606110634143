// import lib
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import View from 'ln/view/View';
import { ListRenderer } from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import IoC from 'ln/ioc/IoC';
import ElementModel from 'lb/models/ElementModel';
import LernSpur from 'ls/models/LernSpur';
import Step from 'ls/models/Step';
import Presentation from 'ls/Presentation';
import { ioc } from 'lb/elements/Chapter';
import Element from 'lb/elements/Element';


/**
 * This class renders a chapter element
 */
class LernSpurElement extends View {
	
	private model:LernSpur;
	private bodyStack:NodeStack;

	constructor( m:LernSpur ) {
		
		super();
		this.defaultTemplate = 'lb.lernspur-element';

		this.model = m;
		this.bodyStack = new NodeStack( Node.fromNative( document.querySelector( '#lernbuch' ) ) );
	}
	
	/**
	 * Renders the chapter into its container.
	 */
	public init() {
		
		this.renderSteps();
		
	}

	public renderSteps() {
		var list = new ListRenderer<Step>( this.node.js( 'steps' ) );
		list.defaultRender( function( step:Step ) {
			var node = Node.fromHTML( Template.render( 'lb.lernspur-step', step ) );
			node.js( 'content' ).html = this.renderSummary( step.get( 'content' ) );

			var s = step;
			node.click.add( function( ) {
				this.showStep( s );
			}.bind( this ));
			
			return { node:node };
		}.bind( this ));

		list.source.fill( this.model.steps );
	}

	public showStep( s:Step ) {
		var p = new Presentation().render() as Presentation;
		p.sidebar.content.ioc = ioc;
		p.show( s );
		p.node.js( 'close' ).click.add( function() {
			p.next.destroy(); // remove keyboard listeners
			this.closeStep();
		}.bind( this ));

		this.bodyStack.stack( p.node );
	}

	public closeStep() {
		this.bodyStack.pop();
	}

	private renderSummary( content:Array<{}> ) {
		var node = Node.fromTag( 'div' );

		var list = new ListRenderer( node );
		list.ioc = ioc;
		list.source.fill( content );

		// remove '.fresh' nodes that show 'NEU'
		node.all( '.fresh' ).forEach( n => n.remove() ); 

		return list.links.all().map( link => link.node.native.textContent ).join( '<br>' );
	}
}

export default LernSpurElement;

interface StackInfo {
	nodes:Node[];
	scroll:{ x:number, y:number };
}

class NodeStack {

	target:Node;
	private _stack:StackInfo[];

	constructor( target:Node ) {
		this.target = target;
		this._stack = [];
	}

	stack( nodes:Node|Node[] ) {

		if( nodes instanceof Node ) nodes = [ nodes ];

		var current = {
			scroll: { x: window.scrollX, y:window.scrollY },
			nodes: this.target.children()
		}

		this._stack.push( current );
		this.target.empty();
		this.target.append( nodes );
		window.scrollTo( 0, 0 );
	}

	pop() {
		var info = this._stack.pop();

		this.target.empty();
		this.target.append( info.nodes );
		window.scrollTo( info.scroll.x, info.scroll.y );
	}
}
