import SearchPattern from './SearchPattern';
import SearchResult from './SearchResult';
import ElementModel from '../models/ElementModel';

/**
 * AtrributeSearch Class.
 */
class AttributeSearch extends SearchPattern {

	public attributes:Array<string>;

	/**
	 * Instantiates an AttributeSearch
	 */
	constructor( attributes ) {
		super();
		this.attributes = attributes;
	}
	
	public search( term:string, element:ElementModel ):SearchResult {
		var result = new SearchResult( element );
		// iterate over all the attributes
		this.attributes.forEach( ( attribute ) => {
			// if there is an attribute, search on the attribute
			if (element.get(attribute)) {
				this.searchString( term, this.stripHTML( element.get(attribute).toString() ), result )
			}
		});
		
		// return the result only when there is any excerpt
		return ( result.excerpts.length > 0 ) ? result : null;
	}

	private stripHTML( text:string ){
		var temp = document.createElement( 'div' );
		temp.innerHTML = text;
		return temp.textContent || temp.innerText;
	}
}

export default AttributeSearch;