import Model from 'ln/model/Model';
import Mapper from 'ln/model/Mapper';

import TermModel from './TermModel';


export default class GlossaryModel extends Model {

    public constructor(dto) {
        super(dto);
    }

    public get terms(): TermModel[] {
        return this.get<TermModel[]>('terms', []);
    }
}


export function register(mapper: Mapper) {
    mapper.toModel.add('App\\Glossary', dto => new GlossaryModel(dto));
}
