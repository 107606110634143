import View from 'ln/view/View';
import Step from './models/Step';
import Presentation from './Presentation';
import ListRenderer from 'ln/list/ListRenderer';
import Template from 'ln/template/TemplateManager';
import Node from 'ln/node/Node';
import HoverTooltip from 'lnui/tooltip/HoverTooltip';

class NextPrevNavigation extends View {

	private presentation: Presentation;
	private current:Step;

	keyListener:EventListenerOrEventListenerObject;
	
    constructor(p: Presentation) {
        super();
        this.defaultTemplate = "";
		this.presentation = p;
		this.presentation.change.add( this.onChange.bind( this ) );

		this.keyListener = this.onKey.bind( this );
	}
	
	init() {
		this.node.js( "next" ).click.add( this.onNext.bind( this ) );
		this.node.js( "prev" ).click.add( this.onPrev.bind( this ) );

		document.addEventListener( "keydown", this.keyListener );

		//var tooltip = new HoverTooltip( { content:"Benutze &#x2192", position:"left", target:this.node.js( "next" ) } ).render();
		//var tooltip = new HoverTooltip( { content:"Benutze &#x2190", position:"right", target:this.node.js( "prev" ) } ).render();
	}

	onChange( step:Step ) {
		this.current = step;

	}


	onKey( e:KeyboardEvent ) {
		// right
		if( e.keyCode == 39 ) this.onNext();
		// left
		if( e.keyCode == 37 ) this.onPrev();
		// space
		if( e.keyCode == 32 ) this.onNext();
	}

	onNext() {
		if( !this.current ) return;
		var next = this.current.next as Step;
		if( next ) this.presentation.show( next );
	}

	onPrev() {
		if( !this.current ) return;
		var next = this.current.previous as Step;
		if( next ) this.presentation.show( next );
	}

	destroy() {
		document.removeEventListener( "keypress", this.keyListener );
	}
}

export default NextPrevNavigation;