import List from 'ln/list/List';
import Model from 'ln/model/Model';
import Media from './Media';
import Step from './Step';

export class LernSpur extends Model {

	get mediaList():Media[] {
		return this.get( 'medialist', [] );
	}

    /**
     *  Returns all steps among all media in a the specified order.
     *
     * @returns {Step[]}
     */
    get steps():Step[] {
        var steps:Step[] = [];

        this.mediaList.forEach(m => {
            steps = steps.concat(m.steps);
        });

        var list = new List( steps );
        list.sortBy( step => {
            return parseInt( step.get( 'index' ) );
        });

        return list.all();
    }
}

export default LernSpur;